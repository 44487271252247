import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Divider } from "@material-ui/core";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>Footer</footer>
    </React.Fragment>
  );
};

export default Footer;
