import React from "react";

const PhoneVerification = ({ setPhoneNumber }) => {

  return (
    <div>
      PhoneVerification
    </div>
  );
};

export default PhoneVerification;
