export const COLORS = {
  black: "#000",
  white: "#fff",
  gray: "#6A6A6A",
  gray100: "#ccc",
  primary: "#0046B6",
  primary100: "#e7effa",
  primary200: "#dae9fe",
  primary300: "#004789",
};
